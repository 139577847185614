import React from 'react'
import styled from '@emotion/styled'

import PropTypes from 'prop-types';
import { Link } from "gatsby"
import { search } from 'styles';
import PostTags from 'components/Post/PostTags'

const MAX_EXCERPTS = 4;

function replaceTags(text: string) {
    const highlightStartRx = /<ais-highlight-\d+>/gi;
    const highlightEndRx = /<\/ais-highlight-\d+>/gi;

    const highlightStartTag = '<span class="highlighted">';
    const highlightEndTag = '</span>';

    const removeCharsRx = /[\n\t]/gi;
    const trimStartRx = /^\s+/gi;
    const trimEndRx = /\s+$/gi;

    return text
        .replace(highlightStartRx, highlightStartTag)
        .replace(highlightEndRx, highlightEndTag)
        .replace(removeCharsRx, ' ')
        .replace(trimStartRx, '')
        .replace(trimEndRx, '');
}

function makeExcerpts(text: string) {
    const startTag = '<ais-highlight';
    const endTag = '</ais-highlight';
    const phrases = text.split(/[\.]/);

    const excerpts = [];

    phrases.forEach((phrase, index) => {
        let excerpt: Array<string> = [];

        if (phrase.indexOf(startTag) !== -1) {
            excerpt.push(phrase);

            if (phrase.indexOf(endTag) === -1) {
                for (let i = index + 1; i < phrases.length; i++) {
                    excerpt.push(phrase);

                    if (phrases[i].indexOf(endTag) !== -1) break;
                }
            }
        }

        if (excerpt.length) {
            const textualExcerpt = replaceTags(excerpt.join(' '));

            excerpts.push(textualExcerpt);
        }
    });

    return excerpts.slice(0, MAX_EXCERPTS);
}

interface SearchHitElement {
    value: string;
    matchLevel: string;
    matchedWords: Array<string>
}

const SectionText = styled.div({
    display: 'flex',
    flexDirection: 'column'
});

const Excerpts = styled.fiv({
    flexGrow: 1
})

export function Hit({ hit, filters }) {
    const article = {
        title: '',
        feature_image: hit.feature_image,
        author: hit.authors && hit.authors[0],
        content: [],
        tags: [],
        category: null
    };

    const hasTagsToShow = hit.tags && hit.tags.some(({ name }) => {
        return (!filters || !filters.length) || filters.indexOf(name) !== -1;
    });

    if (!hasTagsToShow) return null;

    const { title, plaintext, tags } = hit._highlightResult;

    if (title.matchedWords && title.matchedWords.length) {
        article.title = makeExcerpts(title.value).join('...');
    } else {
        article.title = title.value;
    }

    if (plaintext.matchedWords && plaintext.matchedWords.length) {
        article.content = makeExcerpts(plaintext.value);
    }

    if (tags) {
        tags.forEach((tag: SearchHitElement) => {
            if (tag.matchedWords && tag.matchedWords.length) {
                const excerpt = makeExcerpts(tag.value).join('...');
                article.tags.push(excerpt);
            }
        });
    }

    if (hit.tags) {
        for (const tag of hit.tags) {
            if (!tag.name.startsWith('#')) {
                article.category = tag;
                break;
            }
        }
    }

    console.log(">> tags:", tags);
    console.log(">> hit.tags:", hit.tags);

    return (
        <article css={{ ...search.hit }}>
            <SectionText className="text">
                <Link to={`/p/${hit.slug}`}>
                    <h3><span dangerouslySetInnerHTML={{ __html: article.title }}></span> <span className="go">›</span></h3>
                </Link>

                <div className="author-category">
                    { article.author.slug &&
                    <div className="author">
                        By <Link to={`/author/${article.author.slug}`}>{article.author.name}</Link>
                    </div>
                    }

                    { article.category &&
                    <div className="category">
                        <Link to={`/c/${article.category.slug}`}>{article.category.name}</Link>
                    </div>
                    }
                    </div>

                <Excerpts>
                    {article.content.map((except: string, index: Number) => (
                        <p key={'' + index} className="excerpt" dangerouslySetInnerHTML={{ __html: except }}></p>
                    ))}
                </Excerpts>

                <div>
                    <PostTags tags={hit.tags.map(tag => ({
                        visibility: tag.name.startsWith("#") ? 'hidden' : 'public',
                        id: tag.slug,
                        name: tag.name,
                        fields: { permalink: `/c/${tag.slug}`}
                    }))} />
                </div>
            </SectionText>

            { article.feature_image &&
            <div className="image">
                <img src={article.feature_image} alt={hit.title} />
            </div>
            }
        </article>
    );
}

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
    filters: PropTypes.arrayOf(PropTypes.string)
};
