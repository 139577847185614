import React from 'react'
import PropTypes from 'prop-types';
import { tagsToFilters } from '../../lib/algolia-client';
import {
    SearchBox,
    RefinementList,
} from 'react-instantsearch-dom';

export function Sidebar({ keyword, onFilter, onKeywordChange }) {
    const filters = tagsToFilters.map((filter, index) => {
        return (
            <section key={index}>
                { filter.caption &&
                <h3>{ filter.caption }</h3>
                }

                <div className="collapsible">
                    {
                        filter.categories.map((category, index1) => (
                            <div className="input-group" key={`${index}-${index1}`}>
                                <input
                                    type="checkbox"
                                    name={category.tag}
                                    id={category.tag}
                                    onChange={event => onFilter(category.tag, event.target.checked)} />
                                <label htmlFor={category.tag}>{category.title}</label>
                            </div>
                        ))
                    }
                </div>
            </section>
        );
    });

    return (
        <div className="sidebar">
            <div className="search-panel">
                <div className="search-panel__filters">
                    <RefinementList attribute="brand" />
                </div>

                <div className="search-panel__results">
                    <SearchBox
                        onChange={event => onKeywordChange && onKeywordChange(event.target.value)}
                        defaultRefinement={keyword}
                        className="searchbox"
                        translations={{ placeholder: '', }}
                    />
                </div>

                <div className="search-filters">
                    <h3>Filter by</h3>

                    <div className="filter-sections">
                        { filters }
                    </div>
                </div>
            </div>
        </div>
    );
}

Sidebar.propTypes = {
    keyword: PropTypes.string,
    onFilter: PropTypes.func.isRequired,
    onKeywordChange: PropTypes.func
};
