import React, { Component } from 'react'
import {
    InstantSearch,
    Hits,
    Pagination
} from 'react-instantsearch-dom';
import { search } from 'styles';
import { Hit } from './Hit';
import { Sidebar } from './Sidebar';
import { searchClient } from '../../lib/algolia-client';
import qs from 'qs';

interface State {
    keyword: String;
    filters: Array<String>;
}

interface Props {
    onFilter: Function
}

const HitWithFilter = ({ filters }) => ({ hit }) => <Hit hit={hit} filters={filters}></Hit>

export default class Search extends Component<Props, State> {
    constructor(props: any) {
        super(props);

        let keyword: string;

        if (typeof location !== 'undefined' && location.search) {
          const params = qs.parse(location.search.replace(/^\?/, ''))
          keyword = params.keyword || '';
        }

        this.state = {
            keyword,
            filters: []
        }
    }

    handleFilter(tag: String, checked: Boolean) {
        const set = new Set(this.state.filters);

        if (checked) {
            set.add(tag);
        } else {
            set.delete(tag);
        }

        const filters = Array.from(set);

        this.setState({ filters });
    };

    handleKeywordChange(keyword: String) {
      this.setState({ keyword });

      if (typeof history !== 'undefined') {
        history.replaceState(null, document.title, `?keyword=${keyword}`);
      }
    }

    render() {
      return (
        <div
          css={{ ...search.search }}
        >
          <InstantSearch
            searchClient={searchClient}
            indexName={process.env.ALGOLIA_INDEX_NAME}
          >
            <Sidebar
              keyword={this.state.keyword}
              onFilter={(tag: String, checked: Boolean) => this.handleFilter(tag, checked)}
              onKeywordChange={keyword => this.handleKeywordChange(keyword)}
            ></Sidebar>

            <div className="search-panel__results">
              <Hits hitComponent={HitWithFilter({ filters: this.state.filters})} />

              <div className="pagination" css={{
                ...search.pagination
              }} >
                <Pagination />
              </div>
            </div>
          </InstantSearch>
        </div >
      );
    }
}
