import React from 'react';
import Layout from 'components/Layout';
import Header from 'components/Header';
import Search from 'components/Search';

const SearchPage = ({ location }) => {
    let keyword: string;

    if (typeof URLSearchParams !== 'undefined') {
        keyword = new URLSearchParams(location.search).get('keyword')
    }

    return (
        <Layout>
            <Header />
            <Search keyword={keyword} />
        </Layout>
    );
}

export default SearchPage
