import algoliasearch from 'algoliasearch/lite';

const algoliaClient = algoliasearch(
    process.env.ALGOLIA_APP_ID,
    process.env.ALGOLIA_SEARCH_KEY
);

interface SearchParams {
    query: string;
    maxValuesPerFacet: number;
    page: number;
    highlightPreTag: string;
    highlightPostTag: string;
    facets: Array<string>;
};

interface SearchRequest {
    indexName: string;
    params: SearchParams;
};

const searchClient = {
    search (requests: Array<SearchRequest>) {
        const shouldSearch = requests.some(({ params: { query } }) => query !== '');
        if (shouldSearch) {
            return algoliaClient.search(requests);
        }
        return Promise.resolve({
            results: [{ hits: [] }],
        });
    },

    searchForFacetValues: algoliaClient.searchForFacetValues,
};

const tagsToFilters = [{
        caption: 'Content Type',
        categories: [{
                tag: '#contenttype: article',
                title: 'Article'
            },
            {
                tag: '#contenttype: case-study',
                title: 'Case Study'
            },
            {
                tag: '#contenttype: comparison',
                title: 'Comparison'
            },  
            {
                tag: '#contenttype: external-link',
                title: 'External Link'
            },
            {
                tag: '#contenttype: how-to',
                title: 'How To'
            },
            {
                tag: '#contenttype: report',
                title: 'Report'
            }
        ]
    },

    {
        caption: 'Tool Type',
        categories: [{
                tag: '#tooltype: assessment',
                title: 'Assessment'
            },
            {
                tag: '#tooltype: brief',
                title: 'Brief'
            },
            {
                tag: '#tooltype: guide',
                title: 'Guide'
            },
            {
                tag: '#tooltype: software',
                title: 'Software'
            },   
            {
                tag: '#tooltype: technology',
                title: 'Technology'
            },
            {
                tag: '#tooltype: template',
                title: 'Template'
            },
            {
                tag: '#tooltype: worksheet',
                title: 'Worksheet'
            },
            {
                tag: '#tooltype: instructional video',
                title: 'Video'
            }
        ]
    },
    {
        caption: 'Role Type',
        categories: [{
                tag: '#roletype: evaluation-data',
                title: 'Evaluation & Data'
            },
            {
                tag: '#roletype: executive-director',
                title: 'Executive Director'
            },
            {
                tag: '#roletype: foundation',
                title: 'Foundation'
            },
            {
                tag: '#roletype: Human-Resources-Operations',
                title: 'HR & Operations'
            },
            {
                tag: '#roletype: policymaker',
                title: 'Policy & Advocacy'
            },
            {
                tag: '#roletype: program-staff',
                title: 'Program Staff'
            }
        ]
    },
    {
        caption: 'Experience',
        categories: [

            {
                tag: '#experience: beginner',
                title: 'Beginner'
            },
            {
                tag: '#experience: intermediate',
                title: 'Intermediate'
            },
            {
                tag: '#experience: advanced',
                title: 'Advanced'
            }
        ]
    }
];

export {
    algoliaClient,
    searchClient,
    tagsToFilters
}
